import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarCharts = (data, type) => {
  return (
    // {/* <!-- Card Body --> */}
    <div className="card-body" style={{ height: "440px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          {data.type ? (
            <>
              <Bar
                dataKey="total_users"
                stackId="Users"
                fill="#FFC107"
                name="Total Users"
              />
              <Bar
                dataKey="total_verified_users"
                stackId="Verified Users"
                fill="#DC3545"
                name="Verified Users"
              />
              <Bar
                dataKey="total_recruiters"
                stackId="Total Recruiters"
                fill="#007BFF"
                name="Total Recruiters"
              />
              <Bar
                dataKey="total_verified_recruiters"
                stackId="Total Verified Recruiters"
                fill="#AAC317"
                name="Total Verified Recruiters"
              />
            </>
          ) : (
            <>
              <Bar
                dataKey="android"
                stackId="android"
                fill="#DC3545"
                name="Android Users"
              />
              <Bar
                dataKey="ios"
                stackId="ios"
                fill="#FFC107"
                name="Ios Users"
              />
              <Bar
                dataKey="web"
                stackId="web"
                fill="#007BFF"
                name="Web Users"
              />
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarCharts;
