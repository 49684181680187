const initState = {
  recruiters: {},
  recruitersResponse: null,
  loading: false,
};

const recruiterReducer = (state = initState, action) => {
  if (action.type === "GET_RECRUITERS") {
    return {
      ...state,
      recruiters: action.recruiters,
      recruitersResponse: action.recruitersResponse,
      loading: action.loading,
    };
  }
  // /// THESE ARE GENERAL APPLICABLE TO ALL API's
  else if (action.type === "RECRUITERS_RESET") {
    return {
      ...state,

      recruitersResponse: null,
      loading: action.loading,
    };
  } else if (action.type === "RECRUITERS_SUCCESS") {
    return {
      ...state,

      recruitersResponse: action.recruitersResponse,
      loading: action.loading,
    };
  } else if (action.type === "RECRUITERS_FAIL") {
    return {
      ...state,

      recruitersResponse: action.recruitersResponse,
      loading: action.loading,
    };
  }

  return state;
};
export default recruiterReducer;
