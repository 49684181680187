const DevicesChart = (data, label, stats) => {
  return (
    <div className="col-lg-6 mb-4">
      {/* <!-- Project Card Example --> */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">{data.label}</h6>
        </div>
        <div className="card-body">
          <h4 className="small font-weight-bold">
            Android Users{" "}
            <span className="float-right">{data.data[0].val}</span>
          </h4>
          <div className="progress mb-4">
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              style={{
                width: `${
                  data.label === "Users Devices"
                    ? (data.data[0].val / data.stats.total_users) * 100
                    : (data.data[0].val / data.stats.total_recruiter) * 100
                }%
                `,
              }}
              aria-valuenow="20"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <h4 className="small font-weight-bold">
            IOS Users <span className="float-right"> {data.data[1].val}</span>
          </h4>
          <div className="progress mb-4">
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{
                width: `${
                  data.label === "Users Devices"
                    ? (data.data[1].val / data.stats.total_users) * 100
                    : (data.data[1].val / data.stats.total_recruiter) * 100
                }%
                  `,
              }}
              aria-valuenow="40"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <h4 className="small font-weight-bold">
            Web Users <span className="float-right"> {data.data[2].val}</span>
          </h4>
          <div className="progress mb-4">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${
                  data.label === "Users Devices"
                    ? (data.data[2].val / data.stats.total_users) * 100
                    : (data.data[2].val / data.stats.total_recruiter) * 100
                }%
                  `,
              }}
              aria-valuenow="60"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevicesChart;
