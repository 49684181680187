import React, { useEffect, useState } from "react";
import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getDashboard,
  updateJobStatus,
  updateJobStatusAdmin,
} from "../../actions/dashboardAction";
import { connect } from "react-redux";
import { getDeleteJob } from "../../actions/deleteJobAction";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Card,
  CardContent,
  ListItem,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { Popup, Position } from "devextreme-react/popup";
import moment from "moment";
import List from "@mui/material/List";
// import DataGrid, {Column, GroupPanel, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { CheckBox, ScrollView, TextBox } from "devextreme-react";

const options = ["Pending Jobs", "Approved Jobs", "Rejected Jobs"];

const jobStatusOptions = [
  // 'Pending',
  "Approved",
  "Rejected",
];

function Home(props) {
  document.title = "Jobs";

  const [page, setPage] = React.useState(0);
  const [jobsListing, setJobsListing] = useState([]);
  const [jobsLength, setJobsLength] = useState(0);
  const [status, setStatus] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [videoPopUp, setVideoPopUp] = useState(false);
  const [videoPopUpTitle, setVideoPopUpTitle] = useState("");
  const [videoPopUpData, setVideoPopUpData] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // JOB STATUS CHANGE
  const [anchorElJob, setAnchorElJob] = React.useState(null);
  const [selectedIndexJob, setSelectedIndexJob] = React.useState(0);

  // SELECTED ROWS
  const [selectionModel, setSelectionModel] = React.useState([]);

  // Job Details PopUp
  const [jobDetailPopUp, setJobDetailPopUp] = useState(false);
  const [jobDetailPopUpTitle, setJobDetailPopUpTitle] = useState("");
  const [jobDetailPopUpData, setJobDetailPopUpData] = useState({});

  const [optionMain, setOptionMain] = useState();

  useEffect(() => {
    dashboardData(page, status);
  }, []);

  useEffect(() => {
    setJobsListing((prev) => (prev = props.dashboardReducer.dashboard.data));
    setJobsLength((prev) => (prev = props.dashboardReducer.dashboard.total));
    setTotalPages(
      (prev) => (prev = props.dashboardReducer.dashboard.last_page)
    );
    setLoading((prev) => (prev = props.dashboardReducer.loading));
  }, [props.dashboardReducer]);

  const dashboardData = async (page, admin_status) => {
    setLoading(false);
    await props.getDashboard(page, admin_status);
    return null;
  };

  const updateJobStatus = async (params, jobstatus) => {
    fetch(`${process.env.REACT_APP_API_END_POINT}/update-job-approve-status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        id: params,
        status: jobstatus,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setLoading(false);
          dashboardData(page, status);
        } else {
          alert(response.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return null;
  };

  const handleChange = (value) => {
    setPage(value);
    dashboardData(value, status);
  };

  // Recruiter PopUp
  const showVideoPopUP = (data) => {
    setVideoPopUpTitle(data["row"].job_title);
    setVideoPopUpData(data["row"].recruiter);
    setVideoPopUp(true);
  };
  const hideVideoPopUP = () => {
    setVideoPopUpTitle("");
    setVideoPopUpData({});
    setVideoPopUp(false);
  };

  // Job Details PopUp
  const showJobDetailPopUP = (data) => {
    setJobDetailPopUpTitle(data["row"].job_title);
    setJobDetailPopUpData(data["row"]);
    setJobDetailPopUp(true);
  };
  const hideJobDetailPopUP = () => {
    setJobDetailPopUpTitle("");
    setJobDetailPopUpData({});
    setJobDetailPopUp(false);
  };

  // MENU
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, option) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    setOptionMain(option);

    if (index === 0) {
      setStatus((prev) => (prev = 0));
      setPage(0);
      dashboardData(0, 0);
    }
    if (index === 1) {
      setStatus((prev) => (prev = 1));
      setPage(0);

      dashboardData(0, 1);
    }
    if (index === 2) {
      setStatus((prev) => (prev = 2));
      setPage(0);

      dashboardData(0, 2);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // JOB STATUS CHANGE
  const openJob = Boolean(anchorElJob);
  const handleClickListItemJobStatus = (event) => {
    setAnchorElJob(event.currentTarget);
  };

  const handleMenuItemClickJob = (event, index) => {
    setSelectedIndexJob(index);
    // if (index === 0) {
    //     updateJobStatus(selectionModel, 0);
    //     setLoading(false);
    //     dashboardData(1, status);
    // }
    if (index === 0) {
      updateJobStatus(selectionModel, 1);
      // setLoading(false);
      // dashboardData(page, status);
    }
    if (index === 1) {
      updateJobStatus(selectionModel, 2);
      // setLoading(false);
      // dashboardData(page, status);
    }
    setAnchorElJob(null);
  };

  const handleCloseMenuJob = () => {
    setAnchorElJob(null);
  };

  const columns = [
    { field: "job_title", headerName: "Title", flex: 1 },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <span>{params["row"].city ? params["row"].city.name : ""}</span>
        </>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <span>{params["row"].country ? params["row"].country.name : ""}</span>
        </>
      ),
    },
    { field: "job_admin_status", headerName: "Status", flex: 1 },
    { field: "total_applicants", headerName: "Applicants", flex: 1 },
    {
      headerName: "Actions",
      flex: 2,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <div
            style={{
              display: "flex",
              "justify-content": "space-between",
              width: "100%",
            }}
          >
            {optionMain !== "Approved Jobs" ? (
              <button
                className="btn btn-success btn-sm"
                style={{ color: "var(--light-purple)", border: "none" }}
                onClick={() => {
                  updateJobStatus([params.id], 1);
                }}
              >
                Accept
              </button>
            ) : (
              <></>
            )}
            {optionMain !== "Rejected Jobs" ? (
              <button
                className="btn btn-danger btn-sm"
                style={{ color: "var(--light-purple)", border: "none" }}
                onClick={() => {
                  updateJobStatus([params.id], 2);
                }}
              >
                Reject
              </button>
            ) : (
              <></>
            )}
            <button
              className="btn btn-primary btn-sm"
              style={{
                color: "var(--light-purple)",
                "background-color": "var(--purple)",
                border: "none",
              }}
              onClick={() => {
                showJobDetailPopUP(params);
              }}
            >
              Job Details
            </button>
            <button
              className="btn btn-primary btn-sm"
              style={{
                color: "var(--light-purple)",
                "background-color": "var(--purple)",
                border: "none",
              }}
              onClick={() => {
                showVideoPopUP(params);
              }}
            >
              View Recruiter
            </button>
          </div>
        </>
      ),
    },
  ];

  if (loading == false) {
    return <FullPageLoader />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="card custom-main-card-styling">
          <div
            className="card-header mt-5 mt-lg-0"
            style={{ "background-color": "var(--purple)" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                "justify-content": "space-between",
              }}
            >
              <h3
                className="m-0"
                style={{ color: "#FFFFFF", "align-self": "center" }}
              >
                <b>Jobs Listed</b>
              </h3>
              <div>
                <div className="filter-btn-container">
                  {selectionModel && selectionModel.length >= 2 ? (
                    <div>
                      <List
                        component="nav"
                        aria-label="Filters settings"
                        sx={{ bgcolor: "var(--purple)" }}
                      >
                        <ListItem
                          button
                          id="lock-button"
                          aria-haspopup="listbox"
                          aria-controls="lock-menu"
                          aria-expanded={openJob ? "true" : undefined}
                          onClick={handleClickListItemJobStatus}
                        >
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              openJob ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openJob ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            // onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Change Status
                          </Button>
                        </ListItem>
                      </List>
                      <Menu
                        id="lock-menu"
                        anchorEl={anchorElJob}
                        open={openJob}
                        onClose={handleCloseMenuJob}
                        MenuListProps={{
                          "aria-labelledby": "lock-button",
                          role: "listbox",
                        }}
                      >
                        {jobStatusOptions.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndexJob}
                            onClick={(event) =>
                              handleMenuItemClickJob(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div>
                    <List
                      component="nav"
                      aria-label="Filters settings"
                      sx={{ bgcolor: "var(--purple)" }}
                    >
                      <ListItem
                        button
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickListItem}
                      >
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          // onClick={handleClick}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          Filters
                        </Button>
                      </ListItem>
                    </List>
                    <Menu
                      id="lock-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "lock-button",
                        role: "listbox",
                      }}
                    >
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) =>
                            handleMenuItemClick(event, index, option)
                          }
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <Box sx={{ height: "75vh", width: "100%" }}>
                  <DataGrid
                    getRowId={(row: any) => row.job_id}
                    rows={jobsListing}
                    rowCount={jobsLength}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    pageSize={15}
                    paginationMode="server"
                    checkboxSelection={true}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    disableSelectionOnClick={true}
                    selectionModel={selectionModel}
                    onPageChange={(newPage) => handleChange(newPage)}
                    columns={columns}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup
        visible={videoPopUp}
        onHiding={hideVideoPopUP}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title={"Recruiter Information"}
        container=".dx-viewport"
        width="60vw"
        height="500px"
      >
        <Position at="center" my="center" of="center" />

        <ScrollView width="100%" height="100%">
          <div className="row main-styled-form-class">
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Name
                </div>
                <div className="dx-field-value">
                  <TextBox disabled={true} value={videoPopUpData["name"]} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Email
                </div>
                <div className="dx-field-value">
                  <TextBox disabled={true} value={videoPopUpData["email"]} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Phone
                </div>
                <div className="dx-field-value">
                  <TextBox disabled={true} value={videoPopUpData["num"]} />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Remaining Job Count
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={videoPopUpData["remain_job_count"]}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Total Posted Jobs
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={videoPopUpData["total_posted_job_count"]}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Phone
                </div>
                <div className="dx-field-value">
                  <TextBox disabled={true} value={videoPopUpData["num"]} />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Membership Expiry
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={
                      videoPopUpData["membership_expiry_date"]
                        ? moment(
                            videoPopUpData["membership_expiry_date"]
                          ).format("yyyy-MM-DD")
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Created
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={
                      videoPopUpData["created_at"]
                        ? moment(videoPopUpData["created_at"]).format(
                            "yyyy-MM-DD"
                          )
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Updated
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={
                      videoPopUpData["updated_at"]
                        ? moment(videoPopUpData["updated_at"]).format(
                            "yyyy-MM-DD"
                          )
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Verified
                </div>
                <div className="dx-field-value">
                  <CheckBox
                    disabled={true}
                    defaultValue={
                      jobDetailPopUpData["verified"] === 1 ? true : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Premium
                </div>
                <div className="dx-field-value">
                  <CheckBox
                    disabled={true}
                    defaultValue={
                      jobDetailPopUpData["premium"] === 1 ? true : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Eco Compliance
                </div>
                <div className="dx-field-value">
                  <CheckBox
                    disabled={true}
                    defaultValue={
                      jobDetailPopUpData["eco_compliance"] === 1 ? true : false
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <h3
            className="mt-4"
            style={{ color: "#FCA120", "font-weight": "700" }}
          >
            Company
          </h3>
          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Name
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={
                      videoPopUpData["company"]
                        ? videoPopUpData["company"]["name"]
                          ? videoPopUpData["company"]["name"]
                          : ""
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Industry
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={
                      videoPopUpData["company"]
                        ? videoPopUpData["company"]["industry"]
                          ? videoPopUpData["company"]["industry"]["name"]
                          : ""
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollView>
      </Popup>

      <Popup
        visible={jobDetailPopUp}
        onHiding={hideJobDetailPopUP}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title={jobDetailPopUpTitle}
        container=".dx-viewport"
        width="60vw"
        height="50vh"
      >
        <Position at="center" my="center" of="center" />
        <ScrollView width="100%" height="100%">
          <div className="row main-styled-form-class">
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Job Description
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={jobDetailPopUpData["job_description"]}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Job Status
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={jobDetailPopUpData["job_admin_status"]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Created At
                </div>
                <div className="dx-field-value">
                  <TextBox disabled={true} value={jobDetailPopUpData["ago"]} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Updated At
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={
                      jobDetailPopUpData["updated_at"]
                        ? moment(jobDetailPopUpData["updated_at"]).format(
                            "yyyy-MM-DD HH:mm:ss a"
                          )
                        : "N/A"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="dx-field">
                    <div
                      className="dx-field-label"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      Currency
                    </div>
                    <div className="dx-field-value">
                      <TextBox
                        disabled={true}
                        value={
                          jobDetailPopUpData["currency"]
                            ? jobDetailPopUpData["currency"]
                            : "N/A"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="dx-field">
                    <div
                      className="dx-field-label"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      Gender
                    </div>
                    <div className="dx-field-value">
                      <TextBox
                        disabled={true}
                        value={
                          jobDetailPopUpData["gender"]
                            ? jobDetailPopUpData["gender"]
                            : "Not Specified"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Experience Required
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={jobDetailPopUpData["experience_req"] + " Years"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="dx-field">
                    <div
                      className="dx-field-label"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      Applicants
                    </div>
                    <div className="dx-field-value">
                      <TextBox
                        disabled={true}
                        value={jobDetailPopUpData["total_applicants"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="dx-field">
                    <div
                      className="dx-field-label"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      Vacancy
                    </div>
                    <div className="dx-field-value">
                      <TextBox
                        disabled={true}
                        value={jobDetailPopUpData["vacancy"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dx-field">
                <div
                  className="dx-field-label"
                  style={{ "font-weight": "700", color: "var(--purple)" }}
                >
                  Salary
                </div>
                <div className="dx-field-value">
                  <TextBox
                    disabled={true}
                    value={
                      jobDetailPopUpData["salary_min"] +
                      " - " +
                      jobDetailPopUpData["salary_max"]
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 main-styled-form-class">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="dx-field">
                    <div
                      className="dx-field-label"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      Work Level
                    </div>
                    <div className="dx-field-value">
                      <TextBox
                        disabled={true}
                        value={
                          jobDetailPopUpData["work_level"]
                            ? jobDetailPopUpData["work_level"]["name"]
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="dx-field">
                    <div
                      className="dx-field-label"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      Status
                    </div>
                    <div className="dx-field-value">
                      <CheckBox
                        disabled={true}
                        defaultValue={
                          jobDetailPopUpData["status"] === 1 ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="dx-field">
                    <div
                      className="dx-field-label"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      Approved
                    </div>
                    <div className="dx-field-value">
                      <CheckBox
                        disabled={true}
                        defaultValue={
                          jobDetailPopUpData["is_admin_approved"] === 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="dx-field row">
                    <div
                      className="dx-field-label col-lg-8"
                      style={{ "font-weight": "700", color: "var(--purple)" }}
                    >
                      International Hiring
                    </div>
                    <div className="dx-field-value col-lg-4">
                      <CheckBox
                        disabled={true}
                        defaultValue={
                          jobDetailPopUpData["international_hiring_status"] ===
                          1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollView>
      </Popup>
    </>
  );
}

const mapStateToProps = (state) => ({
  dashboardReducer: state.dashboardReducer,
  deletejobReducer: state.deletejobReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboard: (page, admin_status) =>
    dispatch(getDashboard(page, admin_status)),
  getDeleteJob: (userId, id) => dispatch(getDeleteJob(userId, id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
