import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import { getRecruiters } from "../../actions/recruitersAction";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../Recruiters/recruiters.css";

function Recruiters(props) {
  document.title = "Recruiters";

  const [page, setPage] = React.useState(0);
  const [recruiters, setRecruiters] = useState([]);
  const [totalRecruiters, setTotalRecruiters] = useState(0);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = React.useState("");

  // SELECTED ROWS
  const [selectionModel, setSelectionModel] = React.useState([]);

  useEffect(() => {
    recruitersData(page);
  }, []);
  useEffect(() => {
    setRecruiters((prev) => (prev = props.recruiterReducer.recruiters.data));
    setTotalRecruiters(
      (prev) => (prev = props.recruiterReducer.recruiters.total)
    );
    setLoading((prev) => (prev = props.recruiterReducer.loading));
  }, [props.recruiterReducer]);

  const recruitersData = async (page, admin_status) => {
    setLoading(false);
    await props.getRecruiters(page, keyword, country, state, city);
    return null;
  };

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);

  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const onButtonClick = async () => {
    setPage(0);
    await props.getRecruiters(page, keyword, country, state, city);
    setLoading(false);

    return null;
  };
  useEffect(() => {
    const getCountries = async () => {
      const countries = await axios.get(
        "https://api.cvvlogs.com/cv-tube/api.v.2/recruiter/web/get_country_list.php"
      );
      setCountries(countries?.data?.data?.country);
    };
    getCountries();
  }, []);

  useEffect(() => {
    setState(null);
    const getStates = async () => {
      const states = await axios.post(
        "https://api.cvvlogs.com/cv-tube/api.v.2/recruiter/web/fetch_state_list.php",
        {
          country_id: country,
        }
      );

      setStates(states?.data?.data?.state_list);
    };
    getStates();
  }, [country]);

  useEffect(() => {
    setCity(null);
    const getStates = async () => {
      const cities = await axios.post(
        "https://api.cvvlogs.com/cv-tube/api.v.2/recruiter/web/fetch_city_list.php",
        {
          country_id: country,
          state_id: state,
        }
      );

      setCities(cities?.data?.data?.city_list);
    };
    getStates();
  }, [state]);

  // Search Box Start

  const searchFunction = async () => {
    setPage(0);

    await props.getRecruiters(page, keyword, country, state, city);
    setLoading(false);

    return null;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchFunction();
    }
  };

  const handleButtonClick = () => {
    searchFunction();
  };

  const changeEvent = (e) => {
    setKeyword(e.target.value.toLocaleLowerCase());
  };

  // Search Box End

  const handleChange = (value) => {
    setPage(value);
    recruitersData(value, keyword);
  };

  const columns = [
    {
      field: `name`,
      headerName: "Name",
      flex: 1,
    },
    {
      field: "company_name",
      headerName: "Company Name",
      flex: 1,
    },
    {
      field: "num",
      headerName: "Phone No.",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },

    {
      field: "country",
      headerName: "Country",
      flex: 1,
      valueGetter: (params) => {
        return params.row.company &&
          params.row.company.country &&
          params.row.company.country.name
          ? params.row.company.country.name
          : "N/A";
      },
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      valueGetter: (params) => {
        return params.row.company &&
          params.row.company.state &&
          params.row.company.state.name
          ? params.row.company.state.name
          : "N/A";
      },
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      valueGetter: (params) => {
        return params.row.company &&
          params.row.company.city &&
          params.row.company.city.name
          ? params.row.company.city.name
          : "N/A";
      },
    },
    {
      field: "total_posted_job_count",
      headerName: "Total Jobs Posted",
      flex: 1,
    },
  ];

  if (loading == false) {
    return <FullPageLoader />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="card custom-main-card-styling">
          <div
            className="card-header mt-5 mt-lg-0"
            style={{ backgroundColor: "var(--purple)" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <h3
                className="m-0"
                style={{
                  color: "#FFFFFF",
                  padding: "30px 0",
                }}
              >
                <b>Recruiters</b>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="top-container" style={{ paddingBottom: "10px" }}>
              <div style={{ display: "flex" }} className="main-child">
                {" "}
                <div>
                  <input
                    type="text"
                    value={keyword}
                    onChange={changeEvent}
                    onKeyDown={handleKeyDown}
                    id="form1"
                    className="form-control child search-box"
                    placeholder="Search"
                  />
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={handleButtonClick}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="col-md-2 main-child">
                <select
                  className="form-control child"
                  aria-label="Default select example"
                  onChange={handleCountryChange}
                  value={country || ""}
                >
                  <option value="">Select Country</option>
                  {countries?.map((x, i) => (
                    <option value={x.country_id} key={i}>
                      {x.country_name !== null && x.country_name !== undefined
                        ? x.country_name
                        : ""}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2 main-child">
                <select
                  className="form-control child"
                  aria-label="Default select example"
                  onChange={handleStateChange}
                  value={state || ""}
                >
                  <option value="">Select State</option>

                  {states?.map((x, i) => (
                    <option value={x.state_id} key={x.state_id}>
                      {x.state_name !== null && x.state_name !== undefined
                        ? x.state_name
                        : ""}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2 main-child">
                <select
                  className="form-control child"
                  aria-label="Default select example"
                  onChange={handleCityChange}
                  value={city || ""}
                >
                  <option value="">Select City</option>
                  {cities?.map((x, i) => (
                    <option value={x.city_id} key={x.city_id}>
                      {x.city_name !== null && x.city_name !== undefined
                        ? x.city_name
                        : ""}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4 text-center">
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={onButtonClick}
                >
                  APPLY FILTER{" "}
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                height: "100%",
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    height: "75vh",
                    width: "100%",
                  }}
                >
                  <DataGrid
                    getRowId={(row: any) => row.id}
                    rows={recruiters}
                    rowCount={totalRecruiters}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    pageSize={15}
                    checkboxSelection={true}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    disableSelectionOnClick={true}
                    selectionModel={selectionModel}
                    paginationMode="server"
                    onPageChange={(newPage) => handleChange(newPage)}
                    columns={columns}
                    sx={{
                      "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  recruiterReducer: state.recruiterReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getRecruiters: (page, keyword, country, state, city) =>
    dispatch(getRecruiters(page, keyword, country, state, city)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Recruiters);
