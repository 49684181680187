const initState = {
  candidates: {},
  candidatesResponse: null,
  loading: false,
};

const candidateReducer = (state = initState, action) => {
  if (action.type === "GET_CANDIDATES") {
    return {
      ...state,
      candidates: action.candidates,
      candidatesResponse: action.candidatesResponse,
      loading: action.loading,
    };
  }
  // /// THESE ARE GENERAL APPLICABLE TO ALL API's
  else if (action.type === "CANDIDATES_RESET") {
    return {
      ...state,

      candidatesResponse: null,
      loading: action.loading,
    };
  } else if (action.type === "CANDIDATES_SUCCESS") {
    return {
      ...state,

      candidatesResponse: action.candidatesResponse,
      loading: action.loading,
    };
  } else if (action.type === "CANDIDATES_FAIL") {
    return {
      ...state,

      candidatesResponse: action.candidatesResponse,
      loading: action.loading,
    };
  }

  return state;
};
export default candidateReducer;
