export const getRecruiters = (page, keyword, country, state, city) => {
  return (dispatch) => {
    fetch(
      `${process.env.REACT_APP_API_END_POINT}/get-recruiters?page=${page + 1}${
        keyword ? `&keyword=${keyword}` : ""
      }&limit=15${country ? `&country_id[]=${country}` : ""}${
        state ? `&state_id[]=${state}` : ""
      }${city ? `&city_id[]=${city}` : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        // body: JSON.stringify({
        //     page: page,
        //     admin_status: admin_status,
        // }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: "GET_RECRUITERS",
            recruiters: response.data,
            recruitersResponse: "got it",
            loading: true,
          });
        } else {
          alert(response.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: "GET_PERSONAL",
          recruiters: {},
          recruitersResponse: null,
          loading: true,
        });
      });
  };
};
