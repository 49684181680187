import React, { useEffect, useState } from "react";
import "./dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import { getDashboard } from "../../actions/homepageAction";

import DevicesChart from "../../Components/dashboard/devicesChart";
import BarCharts from "../../Components/dashboard/barCharts";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

function Dashboard(props) {
  const [usersAndRecruitersStartDate, setUsersAndRecruitersStartDate] =
    useState(null);
  const [usersAndRecruitersEndDate, setUsersAndRecruitersEndDate] =
    useState(null);
  const [usersStartDate, setUsersStartDate] = useState(null);
  const [usersEndDate, setUsersEndDate] = useState(null);
  const [recruitersStartDate, setRecruitersStartDate] = useState(null);
  const [recruitersEndDate, setRecruitersEndDate] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  document.title = "Dashboard";

  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [graphDataForUsersAndRecruiters, setgraphDataForUsersAndRecruiters] =
    useState([]);
  const [monthlyDeviceTypeUserData, setMonthlyDeviceTypeUserData] = useState(
    {}
  );
  const [monthlyDeviceTypeRecruitersData, setMonthlyDeviceTypeRecruitersData] =
    useState({});
  const [piechartUserData, setPiechartUserData] = useState([]);
  const [piechartRecruiterData, setPiechartRecruiterData] = useState([]);

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("success");

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    dashboardData();
  }, []);

  const getFormattedStartDate = (date) => {
    return (
      date.$d.getFullYear() +
      "-" +
      (date.$d.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.$d.getDate().toString().padStart(2, "0")
    );
  };

  const getFormattedEndDate = (date) => {
    return (
      date.$d.getFullYear() +
      "-" +
      (date.$d.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.$d.getDate().toString().padStart(2, "0")
    );
  };

  // Use Effects for date Selection

  useEffect(() => {
    if (usersAndRecruitersStartDate && usersAndRecruitersEndDate) {
      const date1 = usersAndRecruitersStartDate.$d.getFullYear();
      const date2 = usersAndRecruitersEndDate.$d.getFullYear();
      if (date1 === date2) {
        const formattedDateStart = getFormattedStartDate(
          usersAndRecruitersStartDate
        );
        const formattedDateEnd = getFormattedEndDate(usersAndRecruitersEndDate);

        getMonthlyUsersAndRecruiters(formattedDateStart, formattedDateEnd);
      } else {
        setSnackMessage(
          "Please choose the start and end dates from the same year"
        );
        setSnackSeverity("error");

        return setSnackOpen(true);
      }
    } else {
      getMonthlyUsersAndRecruiters();
    }
  }, [usersAndRecruitersStartDate, usersAndRecruitersEndDate]);
  useEffect(() => {
    if (usersStartDate && usersEndDate) {
      const date1 = usersStartDate.$d.getFullYear();
      const date2 = usersEndDate.$d.getFullYear();
      if (date1 === date2) {
        const formattedDateStart = getFormattedStartDate(usersStartDate);
        const formattedDateEnd = getFormattedEndDate(usersEndDate);
        getMonthlyUsers(formattedDateStart, formattedDateEnd);
      } else {
        setSnackMessage(
          "Please choose the start and end dates from the same year"
        );
        setSnackSeverity("error");

        return setSnackOpen(true);
      }
    } else {
      getMonthlyUsers();
    }
  }, [usersStartDate, usersEndDate]);

  useEffect(() => {
    if (recruitersStartDate && recruitersEndDate) {
      const date1 = recruitersStartDate.$d.getFullYear();
      const date2 = recruitersEndDate.$d.getFullYear();
      if (date1 === date2) {
        const formattedDateStart = getFormattedStartDate(recruitersStartDate);
        const formattedDateEnd = getFormattedEndDate(recruitersEndDate);
        getMonthlyRecruiters(formattedDateStart, formattedDateEnd);
      } else {
        setSnackMessage(
          "Please choose the start and end dates from the same year"
        );
        setSnackSeverity("error");

        return setSnackOpen(true);
      }
    } else {
      getMonthlyRecruiters();
    }
  }, [recruitersStartDate, recruitersEndDate]);

  // Use Effects for Date Selection End

  useEffect(() => {
    setStats((prev) => (prev = props.homepageReducer.homepage));
    setLoading((prev) => (prev = props.homepageReducer.loading));
    let piechartUsers = [
      {
        region: "Android Users",
        val: props.homepageReducer.homepage.total_android_users,
      },
      {
        region: "IOS Users",
        val: props.homepageReducer.homepage.total_ios_users,
      },
      {
        region: "Web Users",
        val: props.homepageReducer.homepage.total_web_users,
      },
    ];
    setPiechartUserData((prev) => (prev = piechartUsers));
    let piechartRecruiters = [
      {
        region: "Android Recruiters",
        val: props.homepageReducer.homepage.total_android_recruiter,
      },
      {
        region: "IOS Recruiters",
        val: props.homepageReducer.homepage.total_ios_recruiter,
      },
      {
        region: "Web Recruiters",
        val: props.homepageReducer.homepage.total_web_recruiter,
      },
    ];
    setPiechartRecruiterData((prev) => (prev = piechartRecruiters));
  }, [props.homepageReducer]);

  const dashboardData = async () => {
    setLoading(false);
    await props.getDashboard();
    return null;
  };

  const getMonthlyUsersAndRecruiters = async (
    usersAndRecruitersStartDate,
    usersAndRecruitersEndDate
  ) => {
    await fetch(
      `${process.env.REACT_APP_API_END_POINT}/get-monthly-stats?${
        usersAndRecruitersStartDate ? "start_date=" : ""
      }${usersAndRecruitersStartDate}${
        usersAndRecruitersEndDate ? "&end_date=" : ""
      }${usersAndRecruitersEndDate}&filter_apply_to[]=total_users&filter_apply_to[]=total_verified_users&filter_apply_to[]=total_recruiter&filter_apply_to[]=total_verified_recruiter`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          // Making the DataSource For Graph
          let graphDataForUsersAndRecruiters = [];
          for (let i = 0; i < response.data.total_users.length; i++) {
            let ObjectToMap = {
              month: response.data.total_users[i].month,
            };
            if (
              response.data.total_users[i] &&
              response.data.total_verified_users[i]
            ) {
              ObjectToMap["total_users"] =
                response.data.total_users[i].total_users;
              ObjectToMap["total_verified_users"] =
                response.data.total_verified_users[i].total_verified_users;
            } else {
              ObjectToMap["total_users"] = 0;
              ObjectToMap["total_verified_users"] = 0;
            }
            if (
              response.data.total_recruiter[i] &&
              response.data.total_verified_recruiter[i]
            ) {
              ObjectToMap["total_recruiters"] =
                response.data.total_recruiter[i].total_recruiter;
              ObjectToMap["total_verified_recruiters"] =
                response.data.total_verified_recruiter[
                  i
                ].total_verified_recruiter;
            } else {
              ObjectToMap["total_recruiters"] = 0;
              ObjectToMap["total_verified_recruiters"] = 0;
            }
            graphDataForUsersAndRecruiters.push(ObjectToMap);
          }

          setgraphDataForUsersAndRecruiters(
            (prev) => (prev = graphDataForUsersAndRecruiters)
          );
        }
      })
      .catch((err) => console.log(err));
  };

  // UsersData

  const getMonthlyUsers = async (usersStartDate, usersEndDate) => {
    await fetch(
      `${process.env.REACT_APP_API_END_POINT}/get-monthly-stats?${
        usersStartDate ? "start_date=" : ""
      }${usersStartDate}${
        usersEndDate ? "&end_date=" : ""
      }${usersEndDate}&filter_apply_to[]=device_type_users`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          // Setting Device Type Users Graph Data Source
          let deviceUsers = [];
          Object.keys(response.data.total_device_type_users).map(
            (key, value) => {
              let ObjectToMap = {
                month: key,
                ios: response.data.total_device_type_users[
                  key.toString()
                ].filter((item) => Object.keys(item).includes("ios"))[0]
                  ? response.data.total_device_type_users[
                      key.toString()
                    ].filter((item) => Object.keys(item).includes("ios"))[0][
                      "ios"
                    ]
                  : 0,
                android: response.data.total_device_type_users[
                  key.toString()
                ].filter((item) => Object.keys(item).includes("android"))[0]
                  ? response.data.total_device_type_users[
                      key.toString()
                    ].filter((item) =>
                      Object.keys(item).includes("android")
                    )[0]["android"]
                  : 0,
                web: response.data.total_device_type_users[
                  key.toString()
                ].filter((item) => Object.keys(item).includes("web"))[0]
                  ? response.data.total_device_type_users[
                      key.toString()
                    ].filter((item) => Object.keys(item).includes("web"))[0][
                      "web"
                    ]
                  : 0,
              };
              deviceUsers.push(ObjectToMap);
            }
          );
          setMonthlyDeviceTypeUserData(deviceUsers);
        }
      })
      .catch((err) => console.log(err));
  };

  // RecruitersData

  const getMonthlyRecruiters = async (
    recruitersStartDate,
    recruitersEndDate
  ) => {
    await fetch(
      `${process.env.REACT_APP_API_END_POINT}/get-monthly-stats?${
        recruitersStartDate ? "start_date=" : ""
      }${recruitersStartDate}${
        recruitersEndDate ? "&end_date=" : ""
      }${recruitersEndDate}&filter_apply_to[]=device_type_recruiters`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          // Setting Device Type Recruiters Graph Data Source
          let deviceRecruiters = [];
          Object.keys(response.data.total_device_type_recruiters).map(
            (key, value) => {
              let ObjectToMap = {
                month: key,
                ios: response.data.total_device_type_recruiters[
                  key.toString()
                ].filter((item) => Object.keys(item).includes("ios"))[0]
                  ? response.data.total_device_type_recruiters[
                      key.toString()
                    ].filter((item) => Object.keys(item).includes("ios"))[0][
                      "ios"
                    ]
                  : 0,
                android: response.data.total_device_type_recruiters[
                  key.toString()
                ].filter((item) => Object.keys(item).includes("android"))[0]
                  ? response.data.total_device_type_recruiters[
                      key.toString()
                    ].filter((item) =>
                      Object.keys(item).includes("android")
                    )[0]["android"]
                  : 0,
                web: response.data.total_device_type_recruiters[
                  key.toString()
                ].filter((item) => Object.keys(item).includes("web"))[0]
                  ? response.data.total_device_type_recruiters[
                      key.toString()
                    ].filter((item) => Object.keys(item).includes("web"))[0][
                      "web"
                    ]
                  : 0,
              };
              deviceRecruiters.push(ObjectToMap);
            }
          );
          setMonthlyDeviceTypeRecruitersData(deviceRecruiters);
        }
      })
      .catch((err) => console.log(err));
  };

  const onButtonClick = async () => {
    if (!filterStartDate || !filterEndDate) {
      setSnackSeverity("error");
      setSnackMessage("Please add both start and end dates");
      setSnackOpen(true);
    } else if (filterStartDate && filterEndDate) {
      const formattedDateStart =
        filterStartDate.$d.getFullYear() +
        "-" +
        (filterStartDate.$d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        filterStartDate.$d.getDate().toString().padStart(2, "0");
      const formattedDateEnd =
        filterEndDate.$d.getFullYear() +
        "-" +
        (filterEndDate.$d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        filterEndDate.$d.getDate().toString().padStart(2, "0");
      await props.getDashboard(formattedDateStart, formattedDateEnd);
    }
  };

  if (loading == false) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Snackbar open={snackOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      <body id="page-top">
        {/* <!-- Page Wrapper --> */}

        <div id="wrapper" className="mt-5 mt-lg-0">
          {/* <!-- Begin Page Content --> */}

          <div
            className="col-xl col-md-12 mb-12 "
            style={{ marginBottom: "20px", height: "auto" }}
          >
            <div className="card border-left-primary shadow h-100 mx-auto">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Filter Data{" "}
                    </div>
                  </div>
                  <div className="col-auto" style={{ display: "flex" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        views={["year", "month", "day"]}
                        value={filterStartDate}
                        name="usersStartDate"
                        onChange={(newValue) => {
                          setFilterStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <DatePicker
                        label="End Date"
                        views={["year", "month", "day"]}
                        name="usersEndDate"
                        value={filterEndDate}
                        onChange={(newValue) => {
                          setFilterEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div
                      style={{ paddingLeft: "20px" }}
                      className="mt-0 mt-md-2 mt-lg-2"
                    >
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={onButtonClick}
                      >
                        APPLY FILTER{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {/* <!-- Page Heading --> */}

            {/* <!-- Content Row --> */}

            <div className="row">
              {/* <!-- Earnings (Monthly) Card Example --> */}

              <div className="col-xl col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Total Users{" "}
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {stats ? stats.total_users : ""}{" "}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fas fa-users fa-2x text-gray-300"
                          style={{ color: "green" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Earnings (Monthly) Card Example --> */}

              <div className="col-xl col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                          Verified Users{" "}
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {stats ? stats.total_verified_users : ""}{" "}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fas fa-users fa-2x text-gray-300"
                          style={{ color: "#007BFF" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Earnings (Monthly) Card Example --> */}

              <div className="col-xl col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                          Completed Profiles
                        </div>
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto">
                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                              {stats ? stats.total_complete_profiles : ""}{" "}
                            </div>
                          </div>
                          <div className="col">
                            <div className="progress progress-sm mr-2">
                              <div
                                className="progress-bar bg-info"
                                role="progressbar"
                                style={{
                                  width: `${
                                    (stats.total_complete_profiles /
                                      stats.total_users) *
                                    100
                                  }%`,
                                }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fas fa-clipboard-list fa-2x text-gray-300"
                          style={{ color: "orange" }}
                        >
                          {" "}
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Earnings (Monthly) Card Example --> */}

              <div className="col-xl col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                          Recruiters{" "}
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {stats ? stats.total_recruiter : ""}{" "}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fas fa-user-tie fa-2x text-gray-300"
                          style={{ color: "darkred" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Pending Requests Card Example --> */}

              <div className="col-xl col-md-12 mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                          Verified Recruiters{" "}
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {stats ? stats.total_verified_recruiter : ""}{" "}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fas fa-user-tie fa-2x text-gray-300"
                          style={{ color: "purple" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Content Row --> */}
            <div className="row">
              <DevicesChart
                label="Users Devices"
                data={piechartUserData ? piechartUserData : ""}
                stats={stats}
              />
              <DevicesChart
                label="Recruiters Devices"
                data={piechartRecruiterData ? piechartRecruiterData : ""}
                stats={stats}
              />
            </div>

            {/* <!-- Bar Charts --> */}

            <div className="row">
              {/* <!-- Pie Chart --> */}

              <div className="col-xl-4 col-md-6 col-sm-9">
                <div className="card shadow mb-4">
                  {/* <!-- Card Header - Dropdown --> */}

                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6
                      className="m-0 font-weight-bold text-primary"
                      style={{ width: "50%" }}
                    >
                      Users and Recruiters Summary{" "}
                    </h6>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        views={["year", "month", "day"]}
                        onChange={(newValue) => {
                          setUsersAndRecruitersStartDate(newValue);
                        }}
                        value={usersAndRecruitersStartDate}
                        name="usersAndRecruitersStartDate"
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <DatePicker
                        label="End Date"
                        views={["year", "month", "day"]}
                        name="usersAndRecruitersEndDate"
                        value={usersAndRecruitersEndDate}
                        onChange={(newValue) => {
                          setUsersAndRecruitersEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <BarCharts
                    data={
                      Object.keys(graphDataForUsersAndRecruiters).length !== 0
                        ? graphDataForUsersAndRecruiters
                        : ""
                    }
                    type="graph"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-9">
                <div className="card shadow mb-4">
                  {/* <!-- Card Header - Dropdown --> */}

                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6
                      className="m-0 font-weight-bold text-primary"
                      style={{ width: "50%" }}
                    >
                      Users' Devices Summary{" "}
                    </h6>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        views={["year", "month", "day"]}
                        value={usersStartDate}
                        name="usersStartDate"
                        onChange={(newValue) => {
                          setUsersStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <DatePicker
                        label="End Date"
                        views={["year", "month", "day"]}
                        name="usersEndDate"
                        value={usersEndDate}
                        onChange={(newValue) => {
                          setUsersEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <BarCharts
                    data={
                      Object.keys(monthlyDeviceTypeUserData).length !== 0
                        ? monthlyDeviceTypeUserData
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-9">
                <div className="card shadow mb-4">
                  {/* <!-- Card Header - Dropdown --> */}

                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6
                      className="m-0 font-weight-bold text-primary"
                      style={{ width: "50%" }}
                    >
                      Recruiters' Devices Summary{" "}
                    </h6>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        views={["year", "month", "day"]}
                        name="recruitersStartDate"
                        value={recruitersStartDate}
                        onChange={(newValue) => {
                          setRecruitersStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <DatePicker
                        label="End Date"
                        views={["year", "month", "day"]}
                        name="recruitersEndDate"
                        value={recruitersEndDate}
                        onChange={(newValue) => {
                          setRecruitersEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <BarCharts
                    data={
                      Object.keys(monthlyDeviceTypeRecruitersData).length !== 0
                        ? monthlyDeviceTypeRecruitersData
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.container-fluid --> */}
        </div>
        {/* <!-- End of Main Content --> */}
      </body>
    </>
  );
}

const mapStateToProps = (state) => ({
  homepageReducer: state.homepageReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboard: (page, admin_status) =>
    dispatch(getDashboard(page, admin_status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
